import React from 'react';

import { Link } from 'react-router-dom';

import ormisLogo from "../../Assets/ormis-logo.png"
import listImg from "../../Assets/article-images/ormis/channel-list.png"
import slashImg from "../../Assets/article-images/ormis/slash-command.png"
import slashImg2 from "../../Assets/article-images/ormis/slash-command2.png"

function Ormis() {
  return (
    <>
        <div className="app-header-container">
          <div style={{ display: "flex", alignItems: "center" }}>
              <img src={ormisLogo} style={{ width: "30px" }} />
              <label style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "20px" }}>ORMIS</label>
            </div>
          <a href="https://discord.gg/pvZXeqHdHS"><button className="btn" style={{ backgroundColor: "rgb(91, 32, 154)", borderColor: "rgb(91, 32, 154)" }}>Discord</button></a>
        </div>

          <div className="article-container">
            <div className="article">
                <span className="article-big-title">ORMIS: The Discord Bot!</span> <br /> <br /> <br />
                <span className="article-body">This is ORMIS! A discord bot created by ORDIOUS. ORMIS is made for people to interact with! You can join our Discord server to talk with ORMIS!</span> <br /> <br />
                <span className="article-title">ORMIS IS IN DEVELOPMENT!</span> <br /> <br />
                <span className="article-body">This means that you bassically cannot expect anything at first from ORMIS! It is a very new idea! You can ask limited questions from it now and run limited commands.</span> <br /> <br />
                <span className="article-title">Future Expectations</span> <br /> <br />
                <span className="article-body">It is expected to surf the web and get answers through the web and do much more in the future.</span> <br /> <br /> <br />
                <span className="article-title">ORMIS is now online 24/7!</span> <br /> <br /> <br />
                <span className="article-title">How to work with with it?</span> <br />
                <div className="article-image-container-container">
                    <div className="article-image-container">
                        <img className="article-image bordered" src={listImg} />
                        <span>ORDIOUS' Discord Server Public Channels</span>
                    </div>
                </div> <br /> <br /> <br />
                <span className="article-body">Go to the ORMIS text-channel, and use the command "/ask". Then type your question in the question box.</span> <br /> <br />
                <div className="article-image-container-container">
                    <div className="article-image-container">
                        <img className="article-image bordered" src={slashImg} />
                        <span>ORDIOUS' Discord Server ORMIS' Text-Channel /ask command</span>
                    </div>
                </div> <br />
                <span className="article-title">One other thing you can do with ORMIS!</span> <br /> <br />
                <span className="article-body">You can also use ORMIS to apply for moderation in <Link to="/memedify">Memedify</Link>! These are the steps you have to follow in order to do this:</span><br />
                <span className="article-body">
                  <ul>
                    <li>Go to Memedify, Dashboard, Info, copy one of the One-Use codes (Enable One-Use codes if not enabled already!)</li>
                    <li>Go to the <strong>ormis channel</strong> in discord</li>
                    <li>Enter the command <strong>/apply-memedify-mod</strong>, enter your Memedify username and the copied One-Use code</li>
                    <li><strong>done!</strong></li>
                  </ul>
                </span> 
                <div className="article-image-container-container">
                    <div className="article-image-container">
                        <img className="article-image bordered" src={slashImg2} />
                        <span>ORDIOUS' Discord Server ORMIS' Text-Channel /apply-memedify-mod command</span>
                    </div>
                </div>
                <br /> <br /> <br />
                <span className="article-body">
                    Some questions you can ask using the <strong>/ask</strong> command: <br /> <br />
                    <ul>
                        <li>sup</li>
                        <li>hi</li>
                        <li>who made you?</li>
                        <li>what time is it?</li>
                        <li>members</li>
                        <li>...</li>
                    </ul>
                </span> <br /> <br /> <br />
                <span className="article-big-title">Used libraries and frameworks</span> <br /> <br />
                <span className="article-body">
              
            <ul>
              <li><strong>Discord.js</strong> was used in order to make the bot. <a href="https://discord.js.org/">discord.js.org</a></li>
            </ul>
          </span>

            </div>
          </div>
        
    </>
  )
}

export default Ormis;