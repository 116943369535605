import React from 'react'
import { Link } from 'react-router-dom';

import pageNotFound from '../Assets/page-not-found.png';


function NoPage() {
  return (
    <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div style={{ margin: "10px", textAlign: "center" }}>
            <img src={pageNotFound} style={{ width: "80px" }} /> <br />
            <h1>Error 404: NOT FOUND!</h1>
            <br></br> <br></br> <br></br>
            <span style={{ fontWeight: "bold", fontSize: "24px" }}>Page Not Found!</span> <br /> <br />
            <span>The website might be updating, or the page may have been removed or have never existed.</span> <br />
            <span><strong>ANYWAY!</strong> You can go to the <Link to="/">homepage</Link>!</span>
        </div>
    </div>
  )
}

export default NoPage;