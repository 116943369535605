import React, { useState, useEffect } from 'react';
import { filterTextByCharacter, serverAddress as proxy } from '../custom-modules/customModules';
import './styles/certificateCheck.scss';
import axios from 'axios';

import Loading from '../custom-components/Loading';

import loadingGif from './../Assets/loader.gif';


function CertificateCheck() 
{
  const [loaded, setLoaded] = useState(true);

  const [certCode, setCertCode] = useState("");
  // const [certCodeInput, setCertCodeInput] = useState("");
  const [applying, setApplying] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [notExists, setNotExists] = useState(0);
  const [certInfo, setCertInfo] = useState({});
  const [ctn, setCnt] = useState(0);


  const [errorText, setErrorText] = useState("");

  function applyCertCode(value)
  {
    if (filterTextByCharacter(value, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]) == undefined) return;
    setCertCode(value);
  }

  function resetValidity()
  {
    setCertInfo({});
    setNotExists(0);
  }

  function checkValidity()
  {
    setErrorText("");
    setApplying(true);
    setNotExists(0);
    if (certCode.toString().length < 8)
    {
      setErrorText("The Certificate ID is 8 characters!");
      setApplying(false);
      return;
    }

    axios.post(`${proxy}/getCert`, { id: `#${certCode}` })
      .then((res) => 
      {
        if (res.data.length == 0)
        {
          setNotExists(1);
          setApplying(false);
          // setLoaded(false);
          if (ctn == 0) setCnt(1);
          if (ctn == 1) setCnt(0);
          return;
        }

        let dat = res.data[0];

        if (dat.status == "e")
        {
          setNotExists(1);
          setApplying(false);
          // setLoaded(false);
          if (ctn == 0) setCnt(1);
          if (ctn == 1) setCnt(0);
          return;
        }

        setNotExists(2);
        setCertInfo(dat);
        setApplying(false);
        if (ctn == 0) setCnt(1);
        if (ctn == 1) setCnt(0);
        // setLoaded(false);
      })
      .catch((err) => console.error(err));
  }

  if (!loaded) return <Loading />;

  return (
    <div className="cert-container">
        <h1>This page is for checking the validity of your ORDIOUS certificate.</h1>
        <span>Every Virtual Card or Certificate has a speciefic Certificate ID.<br />Please enter the ID code below (without the #):</span> <br />
        <div className="flex-center">
            <div className="field" style={{ maxWidth: "600px" }}>
                <strong>#</strong>
                <input type="text" className="input-field" maxLength={8} value={certCode} onInput={(e) => applyCertCode(e.target.value)} />
            </div>
        </div> <br />
        {(applying) ? <img src={loadingGif} style={{ width: "60px", height: "60px" }} /> : <button className="btn2" onClick={checkValidity}>Check</button>} <br /> <br />
        <span className="error">{errorText}</span><br /> <br /> <br /> <br />

      {
        (notExists === 2) ?
          <div className="sign-panel">
            <h1 style={{ color: "green" }}>Certificate is valid!</h1>
            <div className="text-align-left" style={{ margin: "10px" }}>
              <span><strong>Certificate ID:</strong> {certInfo.cert_id}</span> <br />
              <span><strong>Owner:</strong> {certInfo.owner}</span> <br />
              <span><strong>Given By: </strong>{certInfo.provider}</span> <br />
              <span><strong>Certificate Type: </strong>{certInfo.type}</span> <br />
              <span><strong>Date of creation: </strong>{certInfo.date_of_creation}</span> <br /> <br />
            </div>

            <span>{certInfo.cert_desc}</span>
          </div>
          :
          (notExists === 1) ?
            <div className="sign-panel">
              <h1 style={{ color: "red" }}>Certificate is invalid!</h1>
              <span>A certificate with this Certificate ID, is either expired or never existed.</span>
            </div>
            :
            <></>
      }
    </div>
  );
}

export default CertificateCheck;