import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './styles/sign.scss';
import { filterText, numberOfCharactersInString, serverAddress as proxy } from '../custom-modules/customModules';
import axios from 'axios';

import { v4 as uuid } from 'uuid';

import ordiousAccountImage from './../Assets/ordious-account-image.png';
import loadingGif from './../Assets/loader.gif';

import hiddenIcon from './../Assets/icons/hidden.png';
import viewIcon from './../Assets/icons/view.png';


function SignUp() 
{
  const [mode, setMode] = useState("desktop");

  // inputs
  const [emailInput, setEmailInput] = useState("");
  const [usernameInput, setUsernameInput] = useState("");
  const [displaynameInput, setDisplaynameInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [repasswordInput, setRepasswordInput] = useState("");
  const [agreementInput, setAgreementInput] = useState(false);

  const [viewPassword, setViewPassword] = useState(false);
  const [viewRepassword, setViewRepassword] = useState(false);

  // use states
  const [errorText, setErrorText] = useState({
    generalError: "",
    emailError: "",
    usernameError: "",
    displaynameError: "",
    passwordError: "",
    repasswordError: "",
    agreementError: ""
  });

  const [signing, setSigning] = useState(false);

  const redirect = useNavigate();

  useEffect(() => 
  {
    if (localStorage.getItem("user") != "" && localStorage.getItem("user") != null) redirect("/account");
  }, []);


    useEffect(() => 
    {
        if (window.innerWidth <= 670) 
        {
          localStorage.setItem("windowMode", "phone");
          setMode("mobile");
        }
        else 
        {
          localStorage.setItem("windowMode", "desktop");  
          setMode("desktop");
        }

        localStorage.setItem("acceptedSize", 670);
    }, []);

    window.addEventListener("resize", () => 
    {
        if (window.innerWidth <= 670) 
        {
          localStorage.setItem("windowMode", "phone");
          setMode("mobile");
        }
        else 
        {
          localStorage.setItem("windowMode", "desktop");
          setMode("desktop");
        }

        
    });

    let bannedCharacters = ["#", "/", "*", "$", "^", "&", "(", ")", "+", "-", "!", "~", "`", " ", '"', "'"];


    function setupEmailField(value)
    {
      if (filterText(value, bannedCharacters, undefined, 80) !== false && numberOfCharactersInString(value, "@") <= 1)
      {
        setEmailInput(filterText(value, bannedCharacters, undefined, 80));
      }
    }

    function setupUsernameField(value)
    {
      if (filterText(value, [...bannedCharacters, "@", ";", ",", ".", "\\", "=", "%", "|", "?", "<", ">"], undefined, 20) !== false)
      {
        setUsernameInput(filterText(value, [...bannedCharacters, "@", ";", ",", ".", "\\", "=", "%", "|", "?", "<", ">"], undefined, 20).toLowerCase());
      }
    }

    function setupDisplaynameField(value)
    {
      if (filterText(value, ["'", '"', "`"], undefined, 30) !== false)
      {
        setDisplaynameInput(filterText(value, ["'", '"', "`"], undefined, 30));
      }
    }

    function setupPasswordField(value)
    {
      if (filterText(value, ["'", '"', "`"], undefined, 50) !== false) setPasswordInput(filterText(value, ["'", '"', "`"], undefined, 50));
    }

    function setupRepasswordField(value)
    {
      if (filterText(value, ["'", '"', "`"], undefined, 50) !== false) setRepasswordInput(filterText(value, ["'", '"', "`"], undefined, 50));
    }



    function signUp()
    {
      setErrorText({
        generalError: "",
        emailError: "",
        usernameError: "",
        displaynameError: "",
        passwordError: "",
        repasswordError: "",
        agreementError: ""
      });

      let hasErrors = false;

      let tempErrorText = {
        generalError: "",
        emailError: "",
        usernameError: "",
        displaynameError: "",
        passwordError: "",
        repasswordError: "",
        agreementError: ""
      };

      setSigning(true);

      if (emailInput.includes("@") == false)
      {
          hasErrors = true;
          tempErrorText.emailError = "Email did not contain the necessary characters!";
      }

      if (emailInput.includes(".") == false)
      {
          hasErrors = true;
          tempErrorText.emailError = "Email did not contain the necessary characters!";
      }

      if (passwordInput.length < 8)
      {
        hasErrors = true;
        tempErrorText = { ...tempErrorText, passwordError: "Password has to at least contain 8 letters!" };
      }
      
      if (passwordInput != repasswordInput)
      {
        hasErrors = true;
        tempErrorText = { ...tempErrorText, repasswordError: "Passwords do not match!" };
      }

        if (emailInput.length == 0)
        {
            hasErrors = true;
            tempErrorText.emailError = "Please fill in the blanks!";
        }
        if (usernameInput.length == 0)
        {
            hasErrors = true;
            tempErrorText.usernameError = "Please fill in the blanks!";
        }
        if (passwordInput.length == 0)
        {
            hasErrors = true;
            tempErrorText.passwordError = "Please fill in the blanks!";
        }
        if (repasswordInput.length == 0)
        {
            hasErrors = true;
            tempErrorText.repasswordError = "Please fill in the blanks!";
        }

      if (!agreementInput)
      {
        hasErrors = true;
        tempErrorText = { ...tempErrorText, agreementError: "In order to be able to create an account, you are required to accept the ORDIOUS' General Terms of Service!" };
      }


      setErrorText(tempErrorText);
      if (hasErrors) 
      {
        setSigning(false);
        return;
      }

      const date = new Date();

      const info = {
        user_id: uuid(),
        username: usernameInput,
        email: emailInput,
        password: passwordInput,
        display_name: displaynameInput,
        date_of_creation: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}-${date.getHours()}:${date.getMinutes()}`,
        premium: "",
        status: "accepted"
      };

      axios.post(`${proxy}/createUser`, info)
        .then((res) => 
        {
          
          let data = res.data.toString();
          let valid = true;

          if (data == "email is taken") 
          {
            tempErrorText = { ...tempErrorText, emailError: "Email is taken!" };
            valid = false;
          }

          if (data == "username is taken") 
          {
            tempErrorText = { ...tempErrorText, usernameError: "Username is taken!" };
            valid = false;
          }

          setSigning(false);
          setErrorText(tempErrorText);
          if (valid === false) return;

          localStorage.setItem("user", info.user_id);
          redirect("/");

        })
        .catch((err) => console.error(err));
    }


  return (
    <div className="sign-container">
        <br /> <br /> <br />
        <div className="signup-panel">
            <div style={{ backgroundColor: "white", border: "1px transparent", borderRadius: "8px" }}>
                <img src={ordiousAccountImage} style={(mode == "desktop") ? { maxHeight: "200px" } : { maxHeight: "120px" }} />
            </div>
            <div className="sign-panel" style={{ maxWidth: "450px" }}>
                <span className="article-title">Create your account</span> <br />
                <div><span className="error">{errorText.generalError}</span></div><br /> <br />
                <div className="top-field-section">
                    <span>Email Address</span>
                </div>
                <div className="field"><input type="text" className="input-field" value={emailInput} onInput={(e) => setupEmailField(e.target.value)} /></div>
                <div className="text-align-left"><span className="error">{errorText.emailError}</span></div> <br /> 

                <div className="top-field-section">
                    <span>Username</span>
                </div>
                <div className="field"><input type="text" className="input-field" value={usernameInput} onInput={(e) => setupUsernameField(e.target.value)} /></div> 
                <div className="text-align-left"><span className="error">{errorText.usernameError}</span></div> <br /> 

                <div className="top-field-section">
                    <span>Display Name</span>
                </div>
                <div className="field"><input type="text" className="input-field" value={displaynameInput} onInput={(e) => setupDisplaynameField(e.target.value)} /></div>  
                <div className="text-align-left"><span className="error">{errorText.displaynameError}</span></div> <br /> 

                <div className="top-field-section">
                    <span>Password</span>
                </div>
                <div className="field">
                  <input type={(viewPassword) == false ? "password" : "text"} className="input-field" value={passwordInput} onInput={(e) => setupPasswordField(e.target.value)} />
                  <button onClick={() => setViewPassword(!viewPassword)} style={{ cursor: "pointer", background: "none", border: "none" }}>
                    <img src={(viewPassword) ? viewIcon : hiddenIcon} style={{ width: "22px" }} />
                  </button>
                </div> 
                <div className="text-align-left"><span className="error">{errorText.passwordError}</span></div> <br /> 

                <div className="top-field-section">
                    <span>Confirm Password</span>
                </div>
                <div className="field">
                  <input type={viewRepassword == false ? "password" : "text"} className="input-field" value={repasswordInput} onInput={(e) => setupRepasswordField(e.target.value)} />
                  <button onClick={() => setViewRepassword(!viewRepassword)} style={{ cursor: "pointer", background: "none", border: "none" }}>
                    <img src={(viewRepassword) ? viewIcon : hiddenIcon} style={{ width: "22px" }} />
                  </button>
                </div> 
                <div className="text-align-left"><span className="error">{errorText.repasswordError}</span></div> <br /> 

                <div style={{ textAlign: "left" }}><input type="checkbox" value={agreementInput} onInput={() => setAgreementInput(!agreementInput)} /> By checking this checkbox, you agree the <br /><Link to="/tos">ORDIOUS' General Terms of Service.</Link></div> 
                <div className="text-align-left"><span className="error">{errorText.agreementError}</span></div> <br /> 

                {(signing) ? <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}><img src={loadingGif} style={{ width: "60px", height: "60px" }} /></div> : <button className="btn2" onClick={signUp}>Create Account</button>} <br /> <br />
                <div style={{ textAlign: "left" }}>
                    <span>You do have an ORDIOUS account? <Link to="/signin">log in!</Link></span> <br />
                    <span><Link to="/why-you-need-an-ordious-account">Why do you need an ORDIOUS account?</Link></span>
                </div>
            </div> 

            
        </div>

        <br /> <br /> <br />
    </div>
  );
}

export default SignUp;