import React from 'react';

import parkourBoomerLogo from './../../Assets/parkour-boomer.png';

// Assets
import themeImg from './../../Assets/article-images/parkour-boomer/theme.png';
import lvl2 from './../../Assets/article-images/parkour-boomer/level2.png';


function ParkourBoomer() {
  return (
    <>
        <div className="app-header-container">
        <div style={{ display: "flex", alignItems: "center" }}>
            <img src={parkourBoomerLogo} style={{ width: "30px" }} />
            <label style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "20px" }}>Parkour Boomer</label>
        </div>
        <a href="https://download.ordious.com/parkour-boomer"><button className="btn">Download</button></a>
        </div>

        <div className="article-container">
            <div className="article">
                <span className="article-big-title">Parkour Boomer: The first ever game created by ORDIOUS!</span> <br />
                <span className="article-body">Parkour Boomer is a retro-style pixelated game created by PopularSun and being released under ORDIOUS Community. It is a platformer 2D parkour game designed for you to play when you're bored!</span>
                <br /> <br />
                <span className="article-title">Current Version: Alpha 0.2.0</span> <br /> <br />
                <div className="article-image-big-container-container">
                    <div className="article-image-container">
                        <img src={themeImg} className="article-image" />
                    </div>
                </div>
                <br /> <br /> <br />
                <span className="article-title">Alpha Testing</span> <br />
                <span className="article-body">The game is in its Alpha stages and it is not fully baked yet! We would appreciate if you take your time to test the game and give us feedback! It would be an experience for you, and it would help us develop a fully baked and amazing game thanks to you!</span> <br /> <br />
                <span className="article-title">The game</span> <br />
                <span className="article-body">As mentioned before, Parkour Boomer is a platformer retro game. You can move your character with <strong>A</strong> and <strong>D.</strong> You can interact with different objects using <strong>E</strong> or <strong>Enter.</strong> You also have dialog boxes, giving you information.</span> <br /> <br />
                <div className="article-image-container-container">
                    <div className="article-image-container">
                        <img src={lvl2} className="article-image" />
                        <span>Parkour Boomer Level 2</span>
                    </div>
                </div>
                <span className="article-title">Accessing the game</span> <br />
                <span className="article-body">The game is now available to publically download and test. We would appreciate your feedback.</span> <br /> <br />
                <span className="article-title">Development</span> <br />
                <span className="article-body">The game was developed using the <strong>GameMaker Studio 2</strong> IDE using <strong>GML</strong> as the programming language that is similar to C++.</span> <br /> <br /> <br />
                <span className="article-title">Used Libraries and Frameworks</span> <br /> <br />
                <ul>
                    <li><strong>GameMaker Studio 2</strong> is a gaming IDE used for creating 2D games.<a style={{ color: "blue" }} href="https://gamemaker.io/">gamemaker.io</a></li>
                    <li>Some of the assets that are used in the game, are 3rd party.</li>
                </ul>

                
            </div>
        </div>
    </>
  );
}

export default ParkourBoomer;