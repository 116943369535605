import React, { useRef } from 'react';
import './styles/install.scss';

import windowsLogo from '../Assets/windows-logo.png';
import appleLogo from '../Assets/apple-logo.png';
import linuxLogo from '../Assets/linux-logo.png';
import androidLogo from '../Assets/android-logo.png';
import playstoreLogo from '../Assets/playstore-logo.png';
import appstoreLogo from '../Assets/appstore-logo.png';

import px from '../Assets/pixel-center-icon.png'


function Install({ name, filename, logo, desktop, mobile, win, mac, lin, and, play, ios, downloadWin, downloadMac, downloadLin, downloadAnd, downloadPlay, downloadAppStore }) 
{
    const downloadLink = useRef();

    console.log(downloadLink.current);

    function download(system)
    {
        let fileName = filename;

        switch (system)
        {
            case "win":
                {
                    downloadLink.current.href = downloadWin;
                    downloadLink.current.download = `${(filename == undefined) ? name : fileName}-WIN`;
                }
                break;

            case "mac":
                {
                    downloadLink.current.href = downloadMac;
                    downloadLink.current.download = `${(filename == undefined) ? name : fileName}-MAC`;
                }
                break;

            case "lin":
                {
                    downloadLink.current.href = downloadLin;
                    downloadLink.current.download = `${(filename == undefined) ? name : fileName}-LINUX`;
                }
                break;
            
            case "and":
                {
                    downloadLink.current.href = downloadAnd;
                    downloadLink.current.download = `${(filename == undefined) ? name : fileName}-ANDROID`;
                }
                break;

            case "ios":
                {
                    downloadLink.current.href = downloadAppStore;
                }
                break;

            case "play":
                {
                    downloadLink.current.href = downloadPlay;
                }
                break;
        }

        downloadLink.current.click();
    }

  return (
    <div className="installation-container">
        <img src={logo} style={{ width: "100px" }} />
        <div style={{ width: "100%", textAlign: "center" }}><span className="installation-big-title">Download Page for the app {name}</span></div> <br /><br /><br />
        <span style={{ fontSize: "16px" }}>This is the download page for the app {name} created by ORDIOUS.<br />If you experience any issues with your computer after installation, please let us know in the ORDIOUS' official Discord server.<br />(the Discord logo up the top will redirect to ORDIOUS' official server.)</span><br /> <br /> <br />
        <span style={{ fontSize: "12px" }}>it is recommended to at least have Microsoft Windows 8, or macOS 10.13 High Sierra installed on your desktop,<br />and it is recommended to at least have Android 9, or iOS (or iPadOS) 11 installed on your mobile or tablet.</span> <br /> <br /> <br /><br />
        {
            (desktop == true) ?
                <div>
                    <span className="installation-title">Desktop Download</span><br /><br />
                    <div className="download-btn-container">
                        {(downloadWin) ? <button className="download-btn download-btn-win" onClick={() => download("win")}><img src={windowsLogo} style={{ width: "30px" }} />Download For Windows</button> : <></>}
                        {(downloadMac) ? <button className="download-btn download-btn-mac" onClick={() => download("mac")}><img src={appleLogo} style={{ width: "30px" }} />Download For macOS</button> : <></>}
                        {(downloadLin) ? <button className="download-btn download-btn-lin" onClick={() => download("lin")}><img src={linuxLogo} style={{ width: "30px" }} />Download For Linux</button> : <></>}
                    </div>
                </div>
            :
                <></>
        }
        {
            (mobile == true) ?
                <div>
                    <br /><br /><br />
                    <span className="installation-title">Mobile / Tablet Download</span><br /><br />
                    <div className="download-btn-container">
                        {(downloadAnd) ? <button className="download-btn download-btn-and" onClick={() => download("and")}><img src={androidLogo} style={{ width: "30px" }} />Download Android APK</button> : <></>}
                        {(downloadPlay) ? <button className="download-btn download-btn-ply" onClick={() => download("play")}><img src={playstoreLogo} style={{ width: "30px" }} />Get it on Google Play</button> : <></>}
                        {(downloadAppStore) ? <button className="download-btn download-btn-ios" onClick={() => download("ios")}><img src={appstoreLogo} style={{ width: "30px" }} />Get it on App Store</button> : <></>}
                    </div>
                </div>
            : 
                <></>
        } <br /><br /> <br />
        <a ref={downloadLink} style={{ display: "none" }}></a>
        <span style={{ fontSize: "12px" }}>Windows and the Windows logo are registered trademarks of Microsoft Corporation.<br />Apple, the Apple logo, macOS, iOS, iPadOS, App Store, and the App Store logo are registered trademarks of Apple Inc.<br />Android, the Android logo, Google Play, and the Google Play logo are registered trademarks of Google Inc.</span>
    </div>
  );
}

export default Install