import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/account.scss';

import Loading from '../custom-components/Loading';
import Pfp from '../custom-components/Pfp';
import axios from 'axios';
import { serverAddress as proxy, splitByCharacter } from '../custom-modules/customModules';

import blockIcon from '../Assets/block.png';


function AccountDetails() 
{
  const [userInfo, setUserInfo] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [userId, setUserId] = useState("");
  const [absoluteUser, setAbsoluteUser] = useState([]);
  const [banned, setBanned] = useState(false);
  const [accountExists, setAccountExists] = useState(true);

  const redirect = useNavigate();

  let pathname = window.location.pathname;

  if (pathname.charAt(pathname.length - 1) == "/") pathname = pathname.slice(0, pathname.length - 1);
  // console.log(pathname.slice(0, pathname.length - 37))
  if (pathname.slice(0, pathname.length - 37) == "/account" && userId == "") setUserId(pathname.slice(pathname.length - 36, pathname.length));

    console.log(userId)

  useEffect(() => 
    {
        if (window.innerWidth <= Number(localStorage.getItem("acceptedSize"))) setIsMobile(true);
        else setIsMobile(false);
    }, []); // initializes the window mode from the first enter

    window.addEventListener("resize", () => 
    {
        if (window.innerWidth <= Number(localStorage.getItem("acceptedSize"))) setIsMobile(true);
        else setIsMobile(false);
    });

  if (loaded == false)
  {
    axios.post(`${proxy}/getUserById`, { id: userId })
    .then((res) => 
    {
        setUserInfo(res.data[0]);
        setAbsoluteUser(res.data);

        setLoaded(true);
        if (res.data.length == 0)
        {
            setAccountExists(false);
            setLoaded(true);
            return;
        }
        else if (res.data.length > 1)
        {
            setAccountExists("error");
            setLoaded(true);
            return;
        }
        else
        {
            setAccountExists(true);
            if (res.data[0].user_id == localStorage.getItem("user")) 
            {
                redirect("/account");
                console.log("aaaa")
            }
        }
        
    })
    .catch((err) => console.error(err));
  }

    useEffect(() => 
    {
        console.log(absoluteUser)
        if (loaded && absoluteUser.length == 0) setAccountExists(false); 
    }, [loaded]);

  if (!loaded) return <Loading />;
  if (banned) return (<div className="content-page"><img src={blockIcon} style={{ maxWidth: "100px" }} /><h3>This account is banned!</h3></div>);
  if (accountExists === false) return (
    <div className="account-page-container" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <span style={{ fontWeight: "bold", fontSize: "30px" }}>This account does not exist!</span> <br />
        <span style={{ fontSize: "16px", color: "gray" }}>This account may be banned or it was never created.</span>
    </div>
  );

  return (
    <div className="account-container">
      <span className="big-title">USER INFO</span>
      <div className="account-panel">
        <div className="account-head" style={(isMobile) ? { flexDirection: "column", justifyContent: "center" } : {}}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
            <Pfp />
            <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", flexDirection: "column" }}>
              <label className="account-name" style={{ fontWeight: "bold", fontSize: "20px" }}>{(userInfo.display_name != "" && userInfo.display_name != " " && userInfo.display_name != null) ? userInfo.display_name : "" }</label> 
              <label className="account-username">{userInfo.username}</label>
              <label style={{ color: "gray" }}>Date of Creation: {splitByCharacter(userInfo.date_of_creation, "-")[0]} (DD/MM/YYYY)</label>
            </div>
          </div>
          {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <button className="btn2" style={{ padding: "10px" }}>Edit</button>
          </div> */}
        </div>
        <label className="account-description" style={(userInfo.profile_desc == "" || userInfo.profile_desc == " " || userInfo.profile_desc == null) ? { display: "none" } : {}}><br /><div><span className="big-title">ABOUT ME</span></div>{userInfo.profile_desc}</label>
      </div> <br /> <br />

      <span className="big-title">ACHIEVEMENTS</span>
      <div className="account-panel" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
        <span className="italic">(none)</span>
      </div> <br /> <br />

      <span className="big-title">USED SERVICES</span>
      <div className="account-panel" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
        <span className="italic">(none)</span>
      </div>
    </div>
  );
}

export default AccountDetails;