import React from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import './styles/ordiousAccount.scss';


function OrdiousAccount() {
  return (
    <div className="article-container">
        <div className="article">
            <span className="article-big-title">What is an ORDIOUS account and why do you need one?</span> <br /> <br />
            <span className="article-body">ORDIOUS account is a way to identify you and store your data in every ORDIOUS service. <br />Services un-like Memedify, that do not have an account system, will use your ORDIOUS account to identify you.</span> <br /> <br /> <br />
            <span className="article-title">Can you explain?</span> <br /> <br />
            <span className="article-body">Sure! For example in the future, we may add a premium option. Then, you can unlock premium features in every ORDIOUS service! Because you bought premium for your ORDIOUS account, you can unlock premium features in every ORDIOUS service!<br />Another example is that in a few years, we may start creating games. If we do so, we would add a game launcher. Then you can save your journey on your ORDIOUS account and you can continue your journey on another device!</span> <br /> <br />
            <span className="article-body">In services which have their own account systems like Memedify, the account system will not be replaced by your ORDIOUS account. But you can then link your ORDIOUS account to those services, so if there is any premium features it will be unlocked.</span> <br /> <br /> <br />
            <span className="article-title">The use</span> <br /> <br />
            <span className="article-body">Currently, the account system will not really be used or be available to be linked to ORDIOUS services. But in the future, when most of the ORDIOUS services get upgraded to Beta or Official Release, then it will be in use. But it is not bad to create one right now!</span> <br /> <br /> <br />
            <span className="article-body">Already have an ORDIOUS account? <Link to="signin">Log In</Link>  to your account<br />Or if you don't have one, <Link to="signup">Create one!</Link></span>
        </div>
    </div>
  );
}

export default OrdiousAccount;