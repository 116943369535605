import React from 'react';

function MemedifyTOS() {
  return (
    <div className="article-container">
        <div className="article">
            <span className="article-big-title">Memedify Terms Of Service</span> <br /> <br /> <br /> <br />
            <span className="article-body">
                <strong>ENGLISH</strong><br /> <br /> <br /> <br />
                YOUR POSTS SHOULD NOT VIOLATE THE MEMEDIFY TERMS OF SERVICE. KNOW THAT IF YOU CREATE A POST IN MEMEDIFY, YOU AGREE TO THE MEMEDIFY'S TERMS OF SERVICE AND ACCEPT THE CONSEQUENCES IF YOU POST SOMETHING ILLEGAL. PLEASE BE AWARE THAT THE TERMS OF SERVICE SHOULD BE READ AT LEAST ONCE BEFORE POSTING IN MEMEDIFY.
                <br /> <br /> <br /> <br />
                <ol className="article-orderedlist">
                    <li>Your post should not contain sexual content or sexual activity. The content should not be posted and if does, it will be removed. It is better that your meme doesn't joke about sexual subjects because if the joke passes an extent, it will be removed.</li>
                    <br /> <br />
                    <li>You have to at least be 12 to be able to use Memedify.</li>
                    <br /> <br />
                    <li>Do not offend any religion, sexuality, race, and/or community. If you do offend someone in your meme, it will be removed and you might get banned for some time if you get much flags from the users.</li>
                    <br /> <br />
                    <li>Do not share any sort of public/private information about someone (do not dox people). If we see that yo shared someones info, your post will be immediately removed when reviewed, and you will be banned. If it was repeated, you will be permanently banned from the platfrom.</li>
                    <br /> <br />
                    <li>Do not post copyrighted content. It will be removed when seen. Please use a portion of a song/video in your meme to not get banned.</li>
                    <br /> <br />
                    <li>Do not attempt to mess with Memedify. Do not Ex. take down any server, leak someone's information, nuke the website with nuking botd, and etc... . It is not good for us, our users, and really there is no reason to do this.</li>
                </ol>
                <br /> <br /> <br />
                Right now, Memedify does not show advertisments. But when it will, there will be an option to not share data with advertisers. An article soon will come out for user privacy in Memedify.
            </span>
        </div>
    </div>
  );
}

export default MemedifyTOS;