import React from 'react';
import Install from '../../custom-components/Install';

import pixelCenterLogo from '../../Assets/pixel-center-icon.png';

import downloadFileWin32 from '../../dw/pixel-center-alpha0.1.1-win.zip';
import downloadFileMac from '../../dw/pixel-center-alpha0.1.1-mac.zip';


function PixelCenterDownload() 
{
  return (
    <Install name="Pixel Center" filename="pixel-center" logo={pixelCenterLogo} downloadWin={downloadFileWin32} downloadMac={downloadFileMac} desktop={true} />
  );
}

export default PixelCenterDownload;