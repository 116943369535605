import React from 'react';
import './styles/home.scss';
import { Link, useNavigate } from "react-router-dom";

import pixelCenterLogo from './../Assets/pixel-center-icon.png';
import connectillaLogo from './../Assets/connectilla-logo.png';
import memedifyLogo from './../Assets/memedify-logo.png';
import ormisLogo from './../Assets/ormis-logo.png';
import parkourBoomerLogo from './../Assets/parkour-boomer.png';

import jsFileImg from './../Assets/js-file.png';


function Home() 
{
  const redirect = useNavigate();

  return (
    <>
    
      <div className="apps-container">
        <span className="mainscreen-title" style={{ marginLeft: "10px", marginRight: "10px" }}>These are the projects made by ORDIOUS!</span>
        <div className="apps">
          {/* <button className="btn">Fuck me</button> */}
          <div className="app" onClick={() => redirect("memedify")}>
            <img src={memedifyLogo} style={{ width: "100px" }} />
            <label>Memedify</label>
          </div>
          <div className="app" onClick={() => redirect("pixel-center")}>
            <img src={pixelCenterLogo} style={{ width: "100px" }} />
            <label>Pixel Center</label>
          </div>
          <div className="app" onClick={() => redirect("connectilla")}>
            <img src={connectillaLogo} style={{ width: "100px" }} />
            <label>Connectilla</label>
          </div>
          <div className="app" onClick={() => redirect("parkour-boomer")}>
            <img src={parkourBoomerLogo} style={{ width: "100px", border: "1px transparent", borderRadius: "15px" }} />
            <label>Parkour Boomer</label>
          </div>
        </div>
        
      </div> <br />
      <br /> <br />
      <div className="article-container">
        <div className="article">
          <span className="article-big-title">ORDIOUS</span> <br /> <br />
          <span className="article-body">ORDIOUS is a start-up software community. It now only has one creator, PopularSun. PopularSun is the founder of the community. The communiuty had some collaborators before but the team is not complete yet. The biggest project that ORDIOUS created (yet), is Memedify. More projects will be created later in the future. <br /> <br />You can join our <a href="https://discord.gg/pvZXeqHdHS">Discord</a> server to be updated on the news.</span> <br /> <br />
          <span className="article-title">Versions</span> <br /> <br />
          <span className="article-body">As you might have already seen, our projects are in their "Alpha" versions. But what does this mean? <br /> <br />ORDIOUS apps have multiple stages to pass until they are complete.</span> <br />
          <span className="article-body">
            <ul>
              <li><strong>Alpha</strong> means that the app is not ready to be published yet. It is public for people to test the app; and for ORDIOUS to fix the bugs. A lot of things will change from Alpha to the official release.</li>
              <li><strong>Beta</strong> means that the app is kind of ready in terms of what was expected for the app to look like. This is the time that the app is kind of ready and fully functional.</li>
              <li><strong>Official Release</strong> means that the app is fully ready and it is now available for anyone to use and only very few bugs could be expected.</li>
            </ul>
          </span> <br /> <br />
          <span className="article-big-title">For developers only: </span>
          <div className="apps-dev-contianer">
            <div onClick={() => redirect("/custom-functions")}><img src={jsFileImg} style={{ width: "30px" }}  /> customFunctions.js</div>
          </div>
        </div>
      </div> <br /> <br />
     
      
    </>
  );
}

export default Home;
