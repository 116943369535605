import React, { useState, useEffect } from 'react'
import { useNavigate, Outlet } from 'react-router-dom';
import './styles/account.scss';

import Loading from '../custom-components/Loading';
import Pfp from '../custom-components/Pfp';
import axios from 'axios';
import { serverAddress as proxy, splitByCharacter } from '../custom-modules/customModules';


function Account() 
{
  const [userInfo, setUserInfo] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [noAcc, setNoAcc] = useState(false);

  let pathname = window.location.pathname

  const redirect = useNavigate();

  useEffect(() => 
    {
        if (window.innerWidth <= Number(localStorage.getItem("acceptedSize"))) setIsMobile(true);
        else setIsMobile(false);
    }, []); // initializes the window mode from the first enter

    window.addEventListener("resize", () => 
    {
        if (window.innerWidth <= Number(localStorage.getItem("acceptedSize"))) setIsMobile(true);
        else setIsMobile(false);
    });

    useEffect(() => 
    {
      let pth = pathname;
      while (pth.charAt(pth.length - 1) === "/")
      {
        pth = pth.slice(0, pth.length - 1);
      }
  
      pathname = pth;
    }, []);

  if (loaded == false)
  {
    if (localStorage.getItem("user") != "" && localStorage.getItem("user") != null)
    {
      axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
        .then((res) => 
        {
          setUserInfo(res.data[0]);
          setLoaded(true);
        })
        .catch((err) => console.error(err));
    }
    else setNoAcc(true);
  }

  // useEffect(() => 
  // {
  //   if (localStorage.getItem("user") == "" || localStorage.getItem("user") == null) redirect("/signin");
  // }, []);

  function logout()
  {
    localStorage.setItem("user", "");
    redirect("/");
  }

  useEffect(() => 
  {
    if (noAcc && (pathname.slice(pathname.length - 7, pathname.length) == "account" || pathname.slice(pathname.length - 7, pathname.length) == "ccount/" || pathname.slice(pathname.length - 4, pathname.length) == "edit" || pathname.slice(pathname.length - 5, pathname.length) == "edit/")) redirect("/signin");
  }, []);

  console.log(userInfo.profile_desc);

  if (noAcc && !loaded) setLoaded(true);
  if (pathname.slice(pathname.length - 7, pathname.length) != "account" && pathname.slice(pathname.length - 7, pathname.length) != "ccount/") return (<Outlet />);
  if (!loaded) return <Loading />;

  if (noAcc == false)
  { 
    return (
    <div className="account-container">
      <span className="big-title">USER INFO</span>
      <div className="account-panel">
        <div className="account-head" style={(isMobile) ? { flexDirection: "column", justifyContent: "center" } : {}}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
            <Pfp />
            <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", flexDirection: "column" }}>
              <label className="account-name" style={{ fontWeight: "bold", fontSize: "20px" }}>{(userInfo.display_name != "" && userInfo.display_name != " " && userInfo.display_name != null) ? userInfo.display_name : "" }</label> 
              <label className="account-username">{userInfo.username}</label>
              <label style={{ color: "gray" }}>Date of Creation: {splitByCharacter(userInfo.date_of_creation, "-")[0]} (DD/MM/YYYY)</label>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <button className="btn2" style={{ padding: "10px" }} onClick={() => redirect("/account/edit")}>Edit</button>
            <button className="btn3" onClick={logout}>Log Out</button>
          </div>
        </div>
        <label className="account-description" style={(userInfo.profile_desc == "" || userInfo.profile_desc == " " || userInfo.profile_desc == null) ? { display: "none" } : {}}><br /><div><span className="big-title">ABOUT ME</span></div>{userInfo.profile_desc}</label>
      </div> <br /> <br />

      <span className="big-title">ACHIEVEMENTS</span>
      <div className="account-panel" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
        <span className="italic">(none)</span>
      </div> <br /> <br />

      <span className="big-title">USED SERVICES</span>
      <div className="account-panel" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
        <span className="italic">(none)</span>
      </div>
    </div>
  
    );
  }
  else if (pathname.slice(pathname.length - 7, pathname.length) == "account" || pathname.slice(pathname.length - 7, pathname.length) == "ccount/") redirect("/signin");
}

export default Account;