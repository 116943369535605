import React from 'react';
import jsFileImg from '../../Assets/js-file.png';
import gitHubLogo from './../../Assets/github-icon.png';
import './styles/customFunctions.scss';


function CustomFunctions() 
{
  return (
    <>
      <div className="app-header-container">
        <div style={{ display: "flex", alignItems: "center" }}>
            <img src={jsFileImg} style={{ width: "30px" }} />
            <label style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "20px" }}>Custom-Functions.js</label>
          </div>
        <a href="https://github.com/OrdiousOfficial/custom-functions"><button className="btn" style={{ backgroundColor: "black", borderColor: "black", display: "flex", alignItems: "center", justifyContent: "center" }}><img src={gitHubLogo} style={{ width: "20px", marginRight: "5px" }} /> GitHub</button></a>
      </div>

      <div className="article-container">
        <div className="article">
          <span className="article-big-title">customFunctions.js: JavaScript Library for developers</span> <br /> <br /> <br />
          <span className="article-body" style={{ color: "red" }}><strong style={{ color: "red" }}>ATTENTION: </strong>This tool is for developers only. If you are not a developer, it is recommended to use other general ORDIOUS services instead of this. <br />This article contains professional information.</span> <br /> <br />
          <span className="article-title">What is customFunctions.js?</span> <br /> <br />
          <span className="article-body">It has useful functions to use to make coding faster and better. It is written and it is for JavaScript developers; either using the plain HTML/JS or a framework (like ReactJS or etc...).</span> <br /> <br />
          <span className="article-title">What does the package contain?</span> <br /> <br />
          <span className="article-body">It has two JS (JavaScript) files. <strong>countries.js</strong> and <strong>customFunctions.js</strong>. <br />countries.js has all the countries from A-Z by name and country code.</span> <br /> <br /> 
          <span className="article-title">What does the customFunctions.js contain?</span> <br /> <br />
          <span className="article-body">The functions are listed below:</span> <br /> <br /> <br />
          <span className="article-body">
            <ul className="cf-ul">
              <li>
                <strong>removeIndex(array, index)</strong> <br />
                This gets an array and return the array with the removed value by array index.
              </li>
              <li>
                <strong>removeIndexByValue(array, value)</strong> <br />
                The same as the previous function but instead of removing based on the array index, it will remove by the given value in the array.
              </li>
              <li>
                <strong>arrayShares(arr1, arr2)</strong> <br />
                It will return an array containing the values shared between the 2 given arrays.
              </li>
              <li>
                <strong>arrayWith(arr1, arr2, allowDuplication)</strong> <br />
                It will return an array combining the 2 given arrays.<br />
                The <strong>allowDuplication</strong> argument will determine that wether it should filter the repeating values in the 2 given arrays, or allow duplication.
              </li>
              <li>
                <strong>arrayWithout(arr1, arr2)</strong> <br />
                It will return an array containing the first array values, without the values sharing with array 2.
              </li>
              <li>
                <strong>arrayInherits(arr1, arr2)</strong> <br />
                It will return <strong>true</strong> if all of the values in array 1, is available in array 2.
              </li>
              <li>
                <strong>arrayEquals(arr1, arr2, arrangement)</strong> <br />
                It will return <strong>true</strong> if both of the given arrays have equal values. <br />
                The <strong>arrangement</strong> argument determines if the arrangements of the indexes in the arrays matter on their equality.
              </li>
              <li>
                <strong>sliceStringByValue(str, value, returnSlicedValue, returnSlicedValueWithTheValue)</strong> <br />
                It will return a part of the given string, cutted off from the rest of the string by the <strong>value</strong> (this argument has to be only 1 character!)  <br />
                The <strong>returnSlicedValue</strong> argument determines if the function returns the other sliced value or not. If this argument get to be false, only the first part of the sliced string would be returned. <br />
                The <strong>returnSlicedValueWithTheValue</strong> argument determines if the value that you sliced the string into two separate parts would be returned to you as well. If this is true, the value you sliced the string would be returned at the beginning of the second part of the string. <br />
                If neither of the <strong>returnSlicedValue</strong> nor <strong>returnSlicedValueWithTheValue</strong> is true, the value would be returned as a string; otherwise it would be returned as an array with two string indexes.
              </li>
              <li>
                <strong>numberOfNumbers(num)</strong> <br />
                It will return the length of a number.
              </li>
              <li>
                <strong>numberShortner(num)</strong> <br />
                If the number had decimals, it would remove it and give you an integer.
              </li>
              <li>
                <strong>numberOfCharactersInString(str, char)</strong> <br />
                It will return the amount of a character in a string.
              </li>
              <li>
                <strong>avarage(...numbers)</strong> <br />
                It will return the avarage of given numbers.
              </li>
              <li>
                <strong>filterRepeats(arr)</strong> <br />
                It will remove the values that are repeated in the array and make sure that there is only one copy of a value in the array.
              </li>
              <li>
                <strong>alreadyExists(value, arr)</strong> <br />
                If a value already exists in the given array, it will return <strong>true</strong>.
              </li>
              <li>
                <strong>arrayIncludes(arr, value)</strong> <br />
                If in the given array, the given value exists, the function will return <strong>true</strong>.
              </li>
              <li>
                <strong>reverseString(str)</strong> <br />
                It will reverse a string.
              </li>
              <li>
                <strong>returnIndexByValue(array, value)</strong> <br />
                It will return the index number of the given value, in the given array.
              </li>
              <li>
                <strong>formatBytes(bytes, decimals = 2)</strong> <br />
                It will convert Bytes into MB, GB, TB, etc... . <br />
                The <strong>decimals</strong> argument determines the decimal limit.
              </li>
              <li>
                <strong>splitByCharacter(str, char)</strong> <br />
                This function is similar to <strong>sliceStringByValue()</strong>, but it will return the splitted string parts as many as there are (has not to be only two) in an array.<br /> <br />
                <strong>Example: </strong> splitByCharacter("13/07/2023", "/");  [[OUTPUT]] {"->"} ["13", "07", "2023"]
              </li>
              <li>
                <strong>compareDateWithToday(date, clock)</strong> <br />
                This will compare the given date and time, with the current system date and time. <br />
                <strong>(DD/MM/YYYY)</strong>
              </li>
              <li>
                <strong>filterText(text, detectedCharacters, maxCharacters, minNum, maxNum)</strong> <br />
                This will filter text by the given conditions. <br />
                The <strong>detectedCharacters</strong> argument when not undefined, will not allow any text to be returned if these characters exist in the string. <br />
                The <strong>maxCharacters</strong> argument when not undefined, will not allow any values if the string's length exceeds the maxCharacters value. <br />
                The <strong>minNum</strong> and <strong>maxNum</strong> arguments when not undefined, and if the given text is a number, will be sure the the number will not go higher than maxNum, and if minNum is not undefined, it will not allow numbers lower than minNum.<br />
              </li>
            </ul>
          </span>
        </div>
      </div>
    </>
  );
}

export default CustomFunctions;