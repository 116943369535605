import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Router } from "react-router-dom";

// Pages
import Layout from './pages/Layout';
import Home from './pages/Home';
import News from './pages/News';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Account from './pages/Account';
import AccountDetails from './pages/AccountDetails';
import AccountEdit from './pages/AccountEdit';

import Tos from './pages/docs/Tos';
import PixelCenter from './pages/apps/PixelCenter';
import Memedify from './pages/apps/Memedify';
import MemedifyTOS from './pages/docs/MemedifyTOS';
import Ormis from './pages/apps/Ormis';
import NoPage from './pages/NoPage';
import Downloads from './pages/Downloads';
import CustomFunctions from './pages/docs/CustomFunctions';
import OrdiousAccount from './pages/docs/OrdiousAccount';
import CertificateCheck from './pages/CertificateCheck';
import Connectilla from './pages/apps/Connectilla';
import ParkourBoomer from './pages/apps/ParkourBoomer';

// downloads
import PixelCenterDownload from './pages/downloads/PixelCenterDownload';
import ConnectillaDownload from './pages/downloads/ConnectillaDownload';

export default function App()
{
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="account" element={<Account />}>
            <Route path="edit" element={<AccountEdit />} />
            <Route path=":accountId" element={<AccountDetails />} />
          </Route>
          <Route path="signin" element={<SignIn />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="pixel-center" element={<PixelCenter />} />
          <Route path="connectilla" element={<Connectilla />} />
          <Route path="parkour-boomer" element={<ParkourBoomer />} />
          <Route path="tos" element={<Tos />} />
          <Route path="custom-functions" element={<CustomFunctions />} />
          <Route path="why-you-need-an-ordious-account" element={<OrdiousAccount />} />
          <Route path="memedify" element={<Memedify />}>
            <Route path="tos" element={<MemedifyTOS />} />
          </Route>
          <Route path="downloads" element={<Downloads />}>
            <Route path="pixel-center" element={<PixelCenterDownload />} />
            <Route path="connectilla" element={<ConnectillaDownload />} />
          </Route>
          <Route path="certificate-check" element={<CertificateCheck />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
