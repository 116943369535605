import React from 'react';
import Install from '../../custom-components/Install';

import connectillaLogo from '../../Assets/connectilla-logo.png';

import downloadFile from '../../dw/connectilla-alpha0.1.0-win.zip';


function ConnectillaDownload() 
{
  return (
    <Install name="Connectilla" filename="connectilla" logo={connectillaLogo} downloadWin={downloadFile} desktop={true} />
  );
}

export default ConnectillaDownload;