import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './styles/sign.scss';
import { filterText, numberOfCharactersInString } from '../custom-modules/customModules';
import { serverAddress as proxy } from '../custom-modules/customModules';

import ordiousLogo from './../Assets/ordious-logo.png';

import loadingGif from './../Assets/loader.gif';
import axios from 'axios';

import hiddenIcon from './../Assets/icons/hidden.png';
import viewIcon from './../Assets/icons/view.png';

function SignIn() 
{
    // input values
    const [emailInput, setEmailInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    const [errorText, setErrorText] = useState({
        generalError: "",
        emailError: "",
        passwordError: ""
      });
    const [signing, setSigning] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);

    const redirect = useNavigate();

    useEffect(() => 
    {
      if (localStorage.getItem("user") != "" && localStorage.getItem("user") != null) redirect("/account");
    }, []);

    let bannedCharacters = ["#", "/", "*", "$", "^", "&", "(", ")", "+", "-", "!", "~", "`", " "];


    function setupEmailField(value)
    {
      if (filterText(value, bannedCharacters) !== false && numberOfCharactersInString(value, "@") <= 1)
      {
        setEmailInput(filterText(value, bannedCharacters));
      }
    }
  
    function setupPasswordField(value)
    {
      if (filterText(value, ["'", '"', "`"]) !== false) setPasswordInput(filterText(value, ["'", '"', "`"]));
    }

    function signIn()
    {
      setErrorText({
        generalError: "",
        emailError: "",
        passwordError: ""
      });

      let tempErrorText = {
        generalError: "",
        emailError: "",
        passwordError: ""
      };

      setSigning(true);

        
      let hasErrors = false;

      if (emailInput.includes("@") == false)
      {
        hasErrors = true;
        tempErrorText.emailError = "Email did not contain the necessary characters!";
      }

      if (emailInput.includes(".") == false)
      {
        hasErrors = true;
        tempErrorText.emailError = "Email did not contain the necessary characters!";
      }

      if (emailInput.length == 0)
      {
        hasErrors = true;
        tempErrorText.emailError = "Please fill in the blanks!";
      }
      if (passwordInput.length == 0)
      {
        hasErrors = true;
        tempErrorText.passwordError = "Please fill in the blanks!";
      }

      setErrorText(tempErrorText);
      if (hasErrors)
      {
        setSigning(false);
        return;
      }

      axios.post(`${proxy}/getUser`, { email: emailInput, password: passwordInput })
        .then((res) => 
        {
          let account = res.data;

          if (account.length == 0) setErrorText({ ...errorText, generalError: "The Email or the Password is wrong!" });
          else if (account.length != 1) setErrorText({ ...errorText, generalError: "Something is wrong with the given data." })
          else
          {
            localStorage.setItem("user", account[0].user_id);
            redirect("/");
          }

          setSigning(false);
        })
        .catch((err) => console.error(err));

    }



  return (
    <div className="sign-container">
        <br /> <br /> <br />
        <div className="sign-panel">
            <img src={ordiousLogo} style={{ height: "50px" }} /> <br /> <br />
            <span className="article-title">Welcome back!</span> <br /> 
            <span className="error">{errorText.generalError}</span><br /> <br /> <br />
            <div className="top-field-section">
                <span>Email</span>
            </div>
            <div className="field"><input type="text" className="input-field" value={emailInput} onInput={(e) => setupEmailField(e.target.value)} /></div>
            <div className="text-align-left"><span className="error">{errorText.emailError}</span></div> <br /> <br />

            <div className="top-field-section">
                <span>Password</span>
            </div>
            <div className="field">
              <input type={(viewPassword) == false ? "password" : "text"} className="input-field" value={passwordInput} onInput={(e) => setupPasswordField(e.target.value)} />
              <button onClick={() => setViewPassword(!viewPassword)} style={{ cursor: "pointer", background: "none", border: "none" }}>
                <img src={(viewPassword) ? viewIcon : hiddenIcon} style={{ width: "22px" }} />
              </button>
            </div> 
            <div className="text-align-left"><span className="error">{errorText.passwordError}</span></div> <br /> <br /> <br />
            {(signing) ? <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}><img src={loadingGif} style={{ width: "60px", height: "60px" }} /></div> : <button className="btn2" onClick={signIn}>Sign In</button>} <br /> <br />
            <div style={{ textAlign: "left" }}>
                <span>Don't have an ORDIOUS account, <Link to="/signup">create one!</Link></span> <br />
                <span><Link to="/why-you-need-an-ordious-account">Why do you need an ORDIOUS account?</Link></span>
            </div>
        </div> 
        <br /> <br /> <br />
    </div>
  )
}

export default SignIn;