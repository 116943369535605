import React, { useEffect, useState } from 'react';
import './styles/layout.scss';
import { Outlet, Link, useNavigate } from "react-router-dom";

import ordiousLogo from '../Assets/ordious-logo.png';
import ordiousLogoShort from '../Assets/ordious-logo-short.png';
import discordLogo from '../Assets/discord-icon.png';
import megaphone from "../Assets/megaphone.png";
import accountIcon from '../Assets/icons/account.png';

function Layout() 
{
  const domainName = "ordious.com";
  const redirect = useNavigate();

  const [mode, setMode] = useState("desktop");

  useEffect(() => 
  {
    let fullPathname = window.location.href;
    if (fullPathname.slice(0, 7) == "http://")
    {
      window.location.replace(`https://${domainName}`);
    }
  }, []);

  useEffect(() => 
    {
        if (window.innerWidth <= 670) 
        {
          localStorage.setItem("windowMode", "phone");
          setMode("mobile");
        }
        else 
        {
          localStorage.setItem("windowMode", "desktop");  
          setMode("desktop");
        }

        localStorage.setItem("acceptedSize", 670);
    }, []);

    window.addEventListener("resize", () => 
    {
        if (window.innerWidth <= 670) 
        {
          localStorage.setItem("windowMode", "phone");
          setMode("mobile");
        }
        else 
        {
          localStorage.setItem("windowMode", "desktop");
          setMode("desktop");
        }

        
    });

    // console.log(mode);

  return (
    <>
      <nav className="navigation-bar-container">
        <div className="navigation-bar">
          <div className="navigation-left">
            <div className="logo-container" style={{ cursor: "pointer" }} onClick={() => redirect("/")}>
              <img src={(mode == "desktop") ? ordiousLogo : ordiousLogoShort} style={{ height: "65px" }} />
              {/* <label style={{ fontSize: "20px" }}>by <strong>PopularSun</strong></label> */}
            </div>
          </div>
          <div className="navigation-right">
            {/* <Link to="news"><img src={megaphone} style={{ width: "40px", marginRight: "10px" }} /></Link> */}
            <a className="navigation-bar-btn" href="https://discord.gg/pvZXeqHdHS"><img src={discordLogo} style={{ width: "30px" }} /></a>
            <Link to="/account"><button className="navigation-bar-btn"><img src={accountIcon} style={{ width: "30px" }} /></button></Link>
          </div>
        </div>
      </nav>
      <Outlet />

      <div className="bottom">
            <div className="bottom-content">
                <label>copyright © 2024 ORDIOUS by <strong style={{ color: "gray" }}>PopularSun</strong>. ORDIOUS, the ORDIOUS logo, ordious.com, and every project made by ORDIOUS are all owned by PopularSun.</label>
            </div>
        </div>
    </>
  );
}

export default Layout;
