import React from 'react';

import { Link } from 'react-router-dom';

import pixelCenterLogo from './../../Assets/pixel-center-icon.png';

// Article Images
import initalWindow from './../../Assets/article-images/initial-window.png';
import mainWindow from './../../Assets/article-images/main-window.png';

function PixelCenter() 
{
  return (
    <>
        <div className="app-header-container">
            <div style={{ display: "flex", alignItems: "center" }}>
                <img src={pixelCenterLogo} style={{ width: "30px" }} />
                <label style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "20px" }}>ORDIOUS Pixel Center</label>
            </div>
            <a href="https://download.ordious.com/pixel-center"><button className="btn">Download</button></a>
        </div>

        <div className="article-container">
            <div className="article">
                <span className="article-big-title">Pixel Center</span> <br /> <br /> 
                <span className="article-body">Welcome to ORDIOUS Pixel Center! In the app, you can create 8-bit Pixel Art and convert it to png. It is especially made for 8-bit artist.</span> <br /> <br /> 

                <span className="article-title">Current Version: Alpha 0.1.1</span> <br /> <br />
                <span className="article-body">Pixel Center is now availabe on both Windows and macOS. Also an Application menu is now added to the app (on Mac, it is the Pixel Center menu) that contains the About page and the Quit button.</span> <br /> <br />

                <span className="article-title">Platform</span> <br /> <br />
                <span className="article-body">The app is made with the <strong>Electron JS</strong>, for being cross-platform. The app is currently available on Windows and macOS.</span> <br /> <br />

                <div className="article-image-container-container">
                    <div className="article-image-container">
                        <img src={initalWindow} className="article-image" />
                        <span>Pixel Center Initial Window</span>
                    </div>
                </div> <br />

                <span className="article-title">Initial Window</span> <br /> <br />
                <span className="article-body">The first page that appears when you open the app, is the Initial Window. The Initial Window has two parts; the left part, shows you a rectangle, the size of the page you are setting, and the right part, contains the configurations for your project. You can set the width, the height of the board you want to draw in. There absolutely will be more configurations added to the app.</span> <br /> <br />

                <div className="article-image-container-container">
                    <div className="article-image-container">
                        <img src={mainWindow} className="article-image" />
                        <span>Pixel Center Main Window</span>
                    </div>
                </div> <br />

                <span className="article-title">Main Window</span> <br /> <br />
                <span className="article-body">The main window contains a lot of things! <br />
                    The first thing that you see, is the <strong>pixel board</strong> in the middle of the screen. This is where you can draw! As you can see the width and the height of the border is <strong>10 pixels</strong> as we set it in the initial window. <br /> <br /> 
                    The second thing that you can see here, is the <strong>Toolbar</strong> located at the left of the screen. There are tools there that you can work with! <br />
                    <ul>
                        <li><strong>Pencil Tool</strong>: You can basically draw like a pencil with this tool! You can also change the pencil size from the configuartions section that we will talk about.</li>
                        <li><strong>Eraser Tool</strong>: You can erase anything drawn with pecncil or Paint Bucket tool.</li>
                        <li><strong>Paint Bucket Tool</strong>: Also known as the "floodfill" tool, can make a section a certain color. For example if you draw an empty circle, you select a color, and then you click on the middle of the circle with the paint bucket tool, all the space inside the circle would be the chosen color.</li>
                    </ul> 
                    
                    Then we have the <strong>configuations menu</strong>, where you can clear the board, Save your project, set the color of your pencil or paint bucket tool, and set the pencil size. Other things will also be added in the future here!</span> <br /> <br /> <br />

                    <span className="article-title">Used Libraries and Frameworks</span> <br /> <br />
                    <span className="article-body">
                        <ul>
                            <li><strong>React JS</strong> is a JavaScript UI framework that was used to desgin the app. <a style={{ color: "blue" }} href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiD4vKEpe36AhU9hv0HHfrGDHsQFnoECBAQAQ&url=https%3A%2F%2Freactjs.org%2F&usg=AOvVaw26YbpVhaFnAB4A6G8-4uAs">reactjs.org</a></li>
                            <li><strong>Electron JS</strong> is a framework that has an API that can convert HTML web pages to a desktop application. <a style={{ color: "blue" }} href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiAi9-zpe36AhUZhf0HHX1pBS4QFnoECAoQAQ&url=https%3A%2F%2Fwww.electronjs.org%2F&usg=AOvVaw0UZB3JIHbBzbBdgHDkvmBz">electronjs.org</a></li>
                            <li><strong>SASS</strong> is a CSS modifier that adds more CSS features and has better syntax than plain CSS. <a style={{ color: "blue" }} href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjFwP7Spe36AhX7gf0HHQo2DqAQFnoECA4QAQ&url=https%3A%2F%2Fsass-lang.com%2F&usg=AOvVaw0p_IRgLEbIPRGWtlW7Wph8">sass-lang.com</a></li>
                            <li><strong>electron-builder</strong> is a library that was used to build the app into an executable format. <a style={{ color: "blue" }} href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiJ4OTzpu36AhWVHOwKHQK4BeUQFnoECAgQAQ&url=https%3A%2F%2Fwww.electron.build%2F&usg=AOvVaw1o1QRM68Kh2XoFeYICvFLk">electron.build</a></li>
                            <li><strong>concurrently</strong>, <strong>wait-on</strong>, and <strong>cross-env</strong> were also used in the JSON file to run the app.</li>
                            <li><strong>html2canvas</strong> was used to convert the drawing on the pixel board to a png savable image. <a style={{ color: "blue" }} href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwi9jYaap-36AhVWPuwKHSv4AUwQFnoECBAQAQ&url=https%3A%2F%2Fhtml2canvas.hertzen.com%2F&usg=AOvVaw3Gmybj0HQhdKm68K1XgMQZ">html2canvas.hertzen.com</a></li>
                            <li><strong>electron-is-dev</strong> was used to see if the app is running in dev mode, or not; based on that, we can specify how to load the app. <a style={{ color: "blue" }} href=""></a></li>
                        </ul>
                    </span>
            </div>
        </div>
    </>
  );
}

export default PixelCenter;