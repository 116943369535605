import React from 'react';

function Tos() 
{
  return (
    <div className="article-container">
        <div className="article">
            <span className="article-title">ORDIOUS' General Terms of Service</span> <br /> <br /> <br />
            <strong className="article-body">ENGLISH</strong> <br /> <br />
            <span className="article-body">ATTENTION: By using any ORDIOUS service or having any accounts in any ORDIOUS service, even if they have a separate Terms of Service, you agree to the General ORDIOUS Terms of Service. If you violate any statement in the Terms of Service, you can be punished by being temporarily or permanently banned from using the ORDIOUS services; or even legal actions could be taken.</span> <br /> <br />
            <span className="article-body" >
              <ul className="cf-ul">
                <li>Nothing from the ORDIOUS community should be used illegally. You cannot use the ORDIOUS services as your own service and you do not have the right to use the ORDIOUS brand to your own advantage. You do not have the right to sell ORDIOUS' service's accounts, such as an ORDIOUS Account or a Memedify account. You also do not have the right to sell ORDIOUS products wether they are free to use, or not.</li>
                <li>You cannot modify any ORDIOUS product to your advantage.</li>
                <li>You cannot use any of our services illegally. This means that you cannot break the separate Terms of Service of services for ORDIOUS' services. If this is done, you will be punished inside the service and this will not affect other ORDIOUS services (only if you break the statement that is only stated inside the service's Terms of Service, not the General ORDIOUS Terms of Service.)</li>
                <li>In the ORDIOUS services that you can share media or communicate with other people, you cannot cyberbully anyone, you cannot share any explicit media such as sexual content or violent content, and you cannot share anyone's personal information (you cannot "dox" anyone).</li>
                <li>You do not have the right to abuse the ORDIOUS system. This means that if you do anything to corrupt the ORDIOUS' services, you violated this statement. Also if you waste the ORDIOUS' moderation team by abusing, you will be punished.</li>
                <li>No moderator can abuse its power. They cannot punish any user with no valid reason. If they do abuse their power in any service, they will be permanently banned from using the ORDIOUS services and can never be a moderator. Also a moderator that does not do its job and does not moderate well, their role as a moderator should be taken away.</li>
                <li>Someone that is a moderator, should do the specified job that is given to them. Some moderators should respond to users if they have any problems, some should remove contents which violate the Terms of Service, and others will do their specified job.</li>
                <li>Any given virtual card, physical card, or any certificate, will have a specific code that is stored in the ORDIOUS database. If anyone fake their card or certificate, they will be punished.</li>
                <li>ORDIOUS Team, the ORDIOUS logo, ordious.com, and any service ever made by ORDIOUS, are owned by the founder of the ORDIOUS Team, PopularSun. Although any other team member do have their own share and should be credited on the service they worked on. This means that if there is any profit on the services they created, their share should be given to them.</li>
              </ul>
            </span>
        </div>
    </div>
  );
}

export default Tos;