import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import './styles/downloads.scss';
import { Outlet } from 'react-router-dom';

import downloadImg from '../Assets/blue-download.png';


function Downloads() 
{
  const redirect = useNavigate();

  return (
    <div className="downloads-container">
      <img src={downloadImg} style={{ width: "100px" }} /> <br />
      <span className="downloads-title">The Download page has moved to its separate subdomain!</span>
      <a href="https://download.ordious.com"><button className="btn">download.ordious.com</button></a>
    </div>
  );
}

export default Downloads;