import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/account.scss';

import Loading from '../custom-components/Loading';
import axios from 'axios';
import loadingGif from './../Assets/loader.gif';
import { serverAddress as proxy, filterText } from '../custom-modules/customModules';


function AccountEdit() 
{
    const [loaded, setLoaded] = useState(false);
    const [userInfo, setUserInfo] = useState({});

    const [displayNameInput, setDisplayNameInput] = useState("");
    const [aboutMeInput, setAboutMeInput] = useState("");

    const [saving, setSaving] = useState(false);
    
    const [aboutMeChars, setAboutMeChars] = useState({ current: 0, max: 500 });


    const redirect = useNavigate();

    function setupDisplaynameField(value)
    {
        if (saving) return;
      if (filterText(value, ["'", '"', "`"], undefined, 30) !== false)
      {
        setDisplayNameInput(filterText(value, ["'", '"', "`"], undefined, 30));
      }
    }

    function setupAboutMeField(value)
    {
        if (saving) return;
      if (filterText(value, ["'", '"', "`"], undefined, aboutMeChars.max) !== false)
      {
        setAboutMeInput(filterText(value, ["'", '"', "`"], undefined, aboutMeChars.max));
        setAboutMeChars({ ...aboutMeChars, current: value.length });
      }
    }

    if (!loaded)
    {
        if (localStorage.getItem("user") != "" && localStorage.getItem("user") != null)
        {
            axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
                .then((res) => 
                {
                    setUserInfo(res.data[0]);
                    if (res.data[0].display_name != null && res.data[0].display_name != "") setDisplayNameInput(res.data[0].display_name);
                    if (res.data[0].profile_desc != null && res.data[0].profile_desc != "") setAboutMeInput(res.data[0].profile_desc);
                    setLoaded(true);
                })
                .catch((err) => console.error(err));
        }
        else redirect("/signin")
    }

    function goBack()
    {
        redirect("/account");
        window.location.reload();
    }

    function apply()
    {
        setSaving(true);
        let userData = {
            display_name: displayNameInput,
            aboutMe: aboutMeInput
        };

        axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user") , action: "modifyDetails", property: userData })
            .then((res) => 
            {
                setSaving(false);
            })
            .catch((err) => 
            {
                console.error(err);
                setSaving(false);
            });
    }


    if (!loaded) return <Loading />;

  return (
    <div className="sign-container" style={{ minHeight: "100vh" }}>
        <br /> <br /> <br />
        <div className="sign-panel">
            <div className="text-align-left">{(saving) ? <button className="back-btn" style={{ opacity: .85 }}>Back</button> : <button className="back-btn" onClick={goBack}>Back</button>}</div>
            <span className="article-title">Edit your account</span> <br />  <br /> <br />
            <div className="top-field-section">
                <span>Display Name</span>
            </div>
            <div className="field"><input type="text" className="input-field" value={displayNameInput} onInput={(e) => setupDisplaynameField(e.target.value)} /></div> <br /> <br />

            <div className="top-field-section">
                <span>About Me</span>
            </div>
            <div className="field"><textarea className="textarea-field" value={aboutMeInput} onInput={(e) => setupAboutMeField(e.target.value)} /></div>
            <div className="properties">
                <span></span>
                <div><label>{aboutMeChars.current} / {aboutMeChars.max}</label></div>    
            </div> <br /> <br /> <br />
            {(saving) ? <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}><img src={loadingGif} style={{ width: "60px", height: "60px" }} /></div> : <button className="btn2" onClick={apply}>Save Changes</button>}
        </div>
        <br /> <br /> <br />
    </div>
  );
}

export default AccountEdit;