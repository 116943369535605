import React from 'react';
import { Link } from 'react-router-dom';

import connectillaLogo from './../../Assets/connectilla-logo.png';

import initalWindow from './../../Assets/article-images/connectilla/welcome-window.png';
import dnsWindow from './../../Assets/article-images/connectilla/dns-window.png';
import dnsApplied from './../../Assets/article-images/connectilla/applied-dns.png';



function Connectilla() {
  return (
    <>
        <div className="app-header-container">
            <div style={{ display: "flex", alignItems: "center" }}>
                <img src={connectillaLogo} style={{ width: "30px" }} />
                <label style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "20px" }}>ORDIOUS Connectilla</label>
            </div>
            <a href="https://download.ordious.com/connectilla"><button className="btn">Download</button></a>
        </div>

        <div className="article-container">
            <div className="article">
                <span className="article-big-title">Connectilla, an app for your network</span> <br /> <br />
                <span className="article-body">Connectilla is an app to modify your network settings in a professional way. The goal of this app is to create a hub to change your network settings, save your wanted settings and get information about your network.</span> <br /> <br />
                <span className="article-title">Current Version: Alpha 0.1.0</span> <br /> <br />
                <span className="article-body">Right now, you can only modify your DNS settings. You can <strong>Set your desired DNS, Save your DNS addresses and set the last DNS set with Connectilla.</strong></span> <br /> <br /> <br />
                <div className="article-image-container-container">
                    <div className="article-image-container">
                        <img src={initalWindow} className="article-image" />
                        <span>Connectilla's Welcome Page</span>
                    </div>
                </div> <br /> <br />
                <span className="article-title">Connectilla's DNS page</span> <br /><br />
                <span className="article-body">At the top of the page, you have 2 inputs. The Primary DNS address and the Secondary DNS address. When you click on the <strong>Set DNS</strong> button, the app would set your current nework's DNS settings to the DNS addresses you entered.</span><br />
                <span className="article-body">Right next to the Set DNS button, you have the <strong>Save</strong> button. When clicked, the current DNS you have entered into the DNS fields are going to be saved.</span> <br />
                <span className="article-body">Under the DNS input panel, you have the last DNS set with the app and then under that, you have your saved DNS addresses. By clicking on the <strong>Set</strong > button, the DNS addresses from your saved DNS record list or the latest DNS you set with Connectilla will be automatically appear on the top DNS input fields.</span> <br /> <br /> <br />
                <div className="article-image-container-container">
                    <div className="article-image-container">
                        <img src={dnsWindow} className="article-image" />
                        <span>Connectilla's DNS Settings Page</span>
                    </div>
                </div> <br /> <br />
                <div className="article-image-container-container">
                    <div className="article-image-container">
                        <img src={dnsApplied} className="article-image" />
                        <span>The DNS addresses get applied message that will appear in the app when the DNS addresses are set</span>
                    </div>
                </div> <br /> <br /> <br />
                <span className="article-title">Used Libraries and Frameworks</span> <br /> <br />
                <span className="article-body">
                    <ul>
                        <li><strong>React JS</strong> is a JavaScript UI framework that was used to desgin the app. <a style={{ color: "blue" }} href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiD4vKEpe36AhU9hv0HHfrGDHsQFnoECBAQAQ&url=https%3A%2F%2Freactjs.org%2F&usg=AOvVaw26YbpVhaFnAB4A6G8-4uAs">reactjs.org</a></li>
                        <li><strong>Electron JS</strong> is a framework that has an API that can convert HTML web pages to a desktop application. <a style={{ color: "blue" }} href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiAi9-zpe36AhUZhf0HHX1pBS4QFnoECAoQAQ&url=https%3A%2F%2Fwww.electronjs.org%2F&usg=AOvVaw0UZB3JIHbBzbBdgHDkvmBz">electronjs.org</a></li>
                        <li><strong>SASS</strong> is a CSS modifier that adds more CSS features and has better syntax than plain CSS. <a style={{ color: "blue" }} href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjFwP7Spe36AhX7gf0HHQo2DqAQFnoECA4QAQ&url=https%3A%2F%2Fsass-lang.com%2F&usg=AOvVaw0p_IRgLEbIPRGWtlW7Wph8">sass-lang.com</a></li>
                        <li><strong>electron-builder</strong> is a library that was used to build the app into an executable format. <a style={{ color: "blue" }} href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiJ4OTzpu36AhWVHOwKHQK4BeUQFnoECAgQAQ&url=https%3A%2F%2Fwww.electron.build%2F&usg=AOvVaw1o1QRM68Kh2XoFeYICvFLk">electron.build</a></li>
                        <li><strong>concurrently</strong>, <strong>wait-on</strong>, and <strong>cross-env</strong> were also used in the JSON file to run the app.</li>
                        <li><strong>electron-is-dev</strong> was used to see if the app is running in dev mode, or not; based on that, we can specify how to load the app. <a style={{ color: "blue" }} href=""></a></li>
                    </ul>
                </span>


            </div>
        </div>
    </>
  );
}

export default Connectilla;